import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { ChartService } from '../../services/chart.service';

@Component({
  selector: 'app-epargne-common',
  templateUrl: './epargne-common.component.html',
  styleUrls: ['./epargne-common.component.scss']
})
export class EpargneCommonComponent implements OnInit {
  public titre = "General overview"
  public titreCamembert = "Repartition to date"
  public titreGrowth = "Overall growth"
  public titreMonthGrowth = "Monthly growth on a year"
  public timeline : any[];

  public upGrowthArray = []
  public downGrowthArray = []
  
  public yearRange = []
  public yearGrowth = []

  public configGrowthAndMonthlyGrowth = {
    responsive: true, 
  }

  public configHist = {
    responsive: true, 
  }
  public configCam = {
    responsive: true, 
  }
  public layoutEpargneHistory = {
    margin: {
      l: 0,
      r: 0,
      b: 20,
      t: 20,
    },
    // height:400,
    // autosize:true,
    
    automargin: true,
    barmode: 'stack',
    showlegend: false,
    legend: {
      x: 0,
      y: 0,
      yanchor : 'top',
      xanchor : 'left',
      orientation: 'h'
    }
  }
  public layoutGrowth = {
    margin: {
      l: 0,
      r: 0,
      b: 20,
      t: 20,
    },
    height:300,
    autosize:true,
    automargin: true,
    barmode: 'stack',
    showlegend: false
  }
  public layoutMonthGrowth = {
    margin: {
      l: 0,
      r: 0,
      b: 20,
      t: 20,
    },
    autosize:true,
    automargin: true,
    height:300,
    barmode: 'group',
    colorway : ['#3E537B', '#F75940', '#984A59', '#2D5C7F', '#6F5592', '#FF8F56', '#008080', '#007ea7'],
    showlegend: true,
    legend: {
      x: 1,
      y: 0.05,
      yanchor : 'bottom',
      xanchor : 'right',
      orientation: 'h'
    }
  }
  public layoutEpargneCamembert = {
    margin: {
      l: 0,
      r: 0,
      b: 20,
      t: 20,
    },
    height:400,
    showlegend: false
  }
  public layoutLiqOrRisque = {
    margin: {
      l: 0,
      r: 5,
      b: 20,
      t: 20,
    },

    height:300,
    showlegend: true,
    autosize:true,
    automargin: true,
    barmode: 'stack',
    legend: {
      x: 0,
      y: 0.95,
      yanchor : 'bottom',
      xanchor : 'left',
      orientation: 'h'
    }
  }

  totalDebt = 0;

  private CZKValueAvantAppart = 25
  public chartDict = {}
  public allLabels = []
  public ownership = {
    "deni": ['pension_savings','savings_account',  "savings_property", "sap_stock_d", "current_account", "action_revolut_d"],
    "thib": ["october", "action_ws", "advize", "appart_otroko", "ceska_sporitelny", "kooperativa", "av_ca","generali_actif_general", 'generali_sci', 'generali_opci',  "pel", "action_sap_t", "term_deposit", "csob_bohatstvi", "csob_velmi_opatrny", "compte_courant", "compte_courant_cz", "ldd", "crypto", "tickets_resto", "action_revolut_t", "eToro", "csob_akciovy", "csob_savings"],
    "common": ["shared_account"],
    "debt": ['debt_kb', 'debt_dad']
  }
  public liquide = {
    "little liquid" : ['pension_savings', "october", "action_ws", "advize", "appart_otroko", "ceska_sporitelny", "kooperativa"],
    "medium liquid" : ['savings_account',  "savings_property", "sap_stock_d", "av_ca","generali_actif_general", 'generali_sci', 'generali_opci',  "pel", "action_sap_t", "term_deposit", "csob_bohatstvi", "csob_velmi_opatrny", "csob_akciovy"],
    "very liquid" : ["current_account", "action_revolut_d", "compte_courant", "compte_courant_cz", "ldd", "crypto", "tickets_resto", "action_revolut_t", "shared_account", "csob_savings", "eToro"],
  }
  public risques = {
    low : ["savings_account", "savings_property", 'current_account', "compte_courant", "compte_courant_cz" ,"ldd", "pel",  "av_ca", "generali_actif_general", "tickets_resto", "appart_otroko", "term_deposit", "csob_bohatstvi", "csob_velmi_opatrny", "csob_savings"],
    medium : ['pension_savings', 'generali_sci', 'generali_opci', "october",  "ceska_sporitelny", "kooperativa"],
    high : ['sap_stock_d', "action_revolut_d", 'action_ws', 'advize', "crypto", 'action_sap_t', "action_revolut_t", "eToro", "csob_akciovy"],
  }
  public colorLiqOrRisqueOrOwnership = {
    deni : "#f9f871",
    low : "#92D050",
    "little liquid" : "#131a20", 
    thib : "#3887a0", 
    medium : "#FFC000", 
    "medium liquid" : "#334252",
    common : "#48cba3",
    high : "#FF5B5B",
    debt : "#f98371",
    "very liquid" : "#8298b0",
  }

  public displayCamembert = true
  public displayingRisk = false
  public isFocused = false
  public lastAmount = 0
  public isDroppedDown = 0;

  public GraphEpargneHistory = {
    data:[],
    layout:this.layoutEpargneHistory,
    config:this.configHist,
  }

  public GraphCamembert = {
    data:[],
    layout:this.layoutEpargneCamembert,
    config:this.configCam
  }

  public GraphGrowth = {
    data:[],
    layout:this.layoutGrowth,
    config:this.configGrowthAndMonthlyGrowth
 }

  public GraphMonthGrowth = {
    data:[],
    layout:this.layoutMonthGrowth,
    config:this.configGrowthAndMonthlyGrowth
  }

  public deltaTimelineD = 0
  public deltaTimelineC = 0

  constructor(private chartService : ChartService, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.fillColors()
    let keys = Object.keys(this.chartDict)
    keys.forEach(key => {
      if (key !== "total_savings") {this.allLabels.push(this.chartDict[key].label)}
    })
    this.chartService.getEpargneHistoryDeni()
      .subscribe(dataD => {
        this.chartService.getEpargneHistory()
          .subscribe(dataT => {
            this.chartService.getEpargneHistoryCommom()
              .subscribe(dataC => {
                this.topoChart(dataD, dataT, dataC)
              })
          })
      })
    this.ref.detectChanges()
  }
  refreshDropDown(){
    this.ref.detectChanges()
  }

  topoChart(dataD, dataT, dataC) {
    this.timeline = dataT.map(({date}) => {
      let initDate = new Date(date.seconds * 1000);
      initDate.setDate(1)
      initDate.setHours(2,0,0,0)
      return initDate.toISOString()
    })
    let timelineD = dataD.map(({date}) => {
      let initDate = new Date(date.seconds * 1000);
      initDate.setDate(1)
      initDate.setHours(0,0,0,0)
      return initDate.toISOString()
    })
    let timelineC = dataC.map(({date}) => {
      let initDate = new Date(date.seconds * 1000);
      initDate.setDate(1)
      initDate.setHours(0,0,0,0)
      return initDate.toISOString()
    })
    this.deltaTimelineD = this.timeline.length - timelineD.length
    this.deltaTimelineC = this.timeline.length - timelineC.length

    this.fillChartDict(dataD, dataT, dataC);
    this.getTotalDebts()
    this.getOwnership()
    // this.plotEpargneHistoryGraph()
    this.plotTodayOverallCamembert()
    this.plotOverallGrowth()
    this.plotMonthlyGrowth()
    this.lastAmount = this.chartDict["total_savings"].histValue[this.chartDict["total_savings"].histValue.length - 1]
    this.titre = `Overview: + ${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(this.lastAmount)} | - ${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(Math.abs(this.totalDebt))}`
    this.titreCamembert = "Repartition to date"
    this.titreGrowth = "Overall Growth"
    this.titreMonthGrowth = "Monthly growth over a year"
    this.ref.detectChanges()
  }

  getOwnership(){
    this.GraphEpargneHistory.data = this.getOwnershipHistory()
    this.GraphEpargneHistory.layout = this.layoutLiqOrRisque
    this.GraphEpargneHistory.config = this.configHist
    window.dispatchEvent(new Event('resize'));
    this.ref.detectChanges()
  }

  getLiquidity(){
    this.GraphEpargneHistory.data = this.getLiqOrRisqHistory("liq")
    this.GraphEpargneHistory.layout = this.layoutLiqOrRisque
    this.GraphEpargneHistory.config = this.configHist
    window.dispatchEvent(new Event('resize'));
    this.ref.detectChanges()
  }

  getOwnershipHistory(){
    let objConcerne = this.ownership
    let result = []
    let keys = Object.keys(objConcerne)
    keys.forEach(key => {
      let currentSum = []
      let typeOwnership = objConcerne[key]
      typeOwnership.forEach(typ => {
        if(typeOwnership.indexOf(typ) == 0) {
          currentSum = this.chartDict[typ].histValue
        } else {
          currentSum = this.sumArray(currentSum, this.chartDict[typ].histValue)
        }
      })
      if(key !== "debt"){
        result.push({
          x : this.timeline,
          y : currentSum,
          // type : 'bar',
          stackgroup:'positive',
          name : key,
          marker : {color : this.colorLiqOrRisqueOrOwnership[key]}
        })
      }  else {
        result.push({
          x : this.timeline,
          y : currentSum,
          // type : 'bar',
          stackgroup:'negative',
          name : key,
          marker : {color : this.colorLiqOrRisqueOrOwnership[key]}
        })
      }
    })
    return result
  }

  getYearGrowth(){
    let ar = Array.from(this.yearGrowth)
    return ar.reverse()
  }

  getLiqOrRisqHistory(liqOrRisq){
    let objConcerne = {}
    if (liqOrRisq === "liq") {objConcerne = this.liquide} else {objConcerne = this.risques}
    let result = []
    let keys = Object.keys(objConcerne)
    keys.forEach(key => {
      let currentSum = []
      let typeLiq = objConcerne[key]
      typeLiq.forEach(typ => {
        if(typeLiq.indexOf(typ) == 0) {
          currentSum = this.chartDict[typ].histValue
        } else {
          currentSum = this.sumArray(currentSum, this.chartDict[typ].histValue)
        }
      })
      result.push({
        x : this.timeline,
        y : currentSum,
        // type : 'bar',
        stackgroup:'one',
        name : key,
        marker : {color : this.colorLiqOrRisqueOrOwnership[key]}
      })
    })
    return result
  }



  getRisk(){
    this.displayingRisk = true
    this.titre = `Risk status`
    this.GraphEpargneHistory.data =this.getLiqOrRisqHistory("risk")
    // Plotly.react(
    //   this.elEpargneHistory.nativeElement,
    //   this.getLiqOrRisqHistory("risk"),
    //   this.layoutLiqOrRisque,
    //   this.configHist
    // )

    window.dispatchEvent(new Event('resize'));
    this.ref.detectChanges()
  }

  focusOn(label){
    // this.checkFocus()
    this.hidePanel()
    this.displayingRisk = false
    this.isFocused = false
    let index = this.allLabels.indexOf(label)
    if(label == "Risque"){
      this.getRisk()
      this.displayingRisk = true
      this.displayCamembert = false
      this.hidePanel()
      this.ref.detectChanges()
    } else if (label == "Liquidity") {
      this.displayCamembert = true
      this.plotEpargneHistoryGraph()
      this.getLiquidity()
      this.plotTodayOverallCamembert()
      this.plotOverallGrowth()
      this.plotMonthlyGrowth()
      this.lastAmount = this.chartDict["total_savings"].histValue[this.chartDict["total_savings"].histValue.length - 1]
      this.ref.detectChanges()
      this.titre = `Liquidity status`
      window.dispatchEvent(new Event('resize'));
    } else if (label == "Totale") {
      this.displayCamembert = true
      this.getOwnership()
      this.plotTodayOverallCamembert()
      this.plotOverallGrowth()
      this.plotMonthlyGrowth()
      this.lastAmount = this.chartDict["total_savings"].histValue[this.chartDict["total_savings"].histValue.length - 1]
      this.ref.detectChanges()
      this.titre = `Overview: + ${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(this.lastAmount)} | - ${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(Math.abs(this.totalDebt))}`
      window.dispatchEvent(new Event('resize'));
    }else if (label !== "Liquidity"){
      this.displayCamembert = false
      this.isFocused = true
      this.titreGrowth = `Growth of ${label}`
      this.titreMonthGrowth = `Mensual growth of ${label} over a year`
      this.titreCamembert = "Répartition à date"
      this.GraphEpargneHistory.data =this.getDataHistory(index)
      this.GraphGrowth.data = this.generateGrowth(index)
      this.GraphMonthGrowth.data = this.generateMonthlyAndYearlyGrowth(index)
      this.ref.detectChanges()
      // Plotly.react(
      //   this.elEpargneHistory.nativeElement,
      //   this.getDataHistory(index),
      //   this.layoutEpargneHistory,
      //   this.configHist
      // ) 
      // Plotly.react(
      //   this.elGrowth.nativeElement,
      //   this.generateGrowth(index),
      //   this.layoutGrowth,
      //   this.configGrowthAndMonthlyGrowth
      // )
      // this.ref.detectChanges()
      //  Plotly.react(
      //     this.elMonthGrowth.nativeElement,
      //     this.generateMonthlyAndYearlyGrowth(index),
      //     this.layoutMonthGrowth,
      //     this.configGrowthAndMonthlyGrowth
      //   )
        this.titre = `${label} | ${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(this.lastAmount)}`
        window.dispatchEvent(new Event('resize'));
        this.ref.detectChanges()
    } 
    this.ref.detectChanges()
  }

  getDataHistory(index) {
    let result = []
    let keys = Object.keys(this.chartDict)
    let key = keys[index]
    result.push({
      x : this.timeline,
      y : this.chartDict[key].histValue,
      mode: 'lines+markers',
      type : 'scatter',
      name : this.chartDict[key].label,
      marker : {color : this.chartDict[key].color}
    })
    this.lastAmount = this.chartDict[key].histValue[this.chartDict[key].histValue.length - 1]
    return result
  }
  
  plotEpargneHistoryGraph(){
    this.GraphEpargneHistory.data = this.generateDataHistory()
    this.ref.detectChanges()
  }

  plotTodayOverallCamembert(){
    this.GraphCamembert.data = this.generateDataCamembert()
    this.ref.detectChanges()
  }

  plotOverallGrowth(){
    this.GraphGrowth.data = this.generateGrowth(this.allLabels.length)
    this.ref.detectChanges()
  }

  plotMonthlyGrowth(){
    this.GraphMonthGrowth.data = this.generateMonthlyAndYearlyGrowth(this.allLabels.length)
    this.ref.detectChanges()
  }

  generateDataCamembert(){
    let result = [{
      values : [],
      labels : [],
      marker : {colors : []},
      hoverinfo: 'label+value',
      direction : "clockwise",
      type : "pie",
      automargin: true
    }]
    let keys = Object.keys(this.chartDict)
    result[0].labels.push('Other')
    result[0].values.push(0)
    result[0].marker.colors.push("#D3D3D3")
    let otherTotal = 0
    for(let i = 0; i < keys.length ; i ++){
      let key = keys[i]
      if (key !== "total_savings" && key !== "debt_kb" && key !== "debt_dad" && this.chartDict[key].todayValue !== 0){
        if(this.chartDict[key].todayValue*1.0 / this.chartDict["total_savings"].histValue[this.chartDict["total_savings"].histValue.length-1] < 0.012){  

          otherTotal += Number(this.chartDict[key].todayValue) 
        } else {
          result[0].values.push(this.chartDict[key].todayValue)
          result[0].labels.push(this.chartDict[key].label)
          result[0].marker.colors.push(this.chartDict[key].color)
        }
      } 
    }
    result[0].values[0] = otherTotal
    return result
  }

  generateDataHistory(){
    let result = []
    let keys = Object.keys(this.chartDict)
    keys.forEach(key => {
      if (key !== "total_savings"){
        result.push({
          x : this.timeline,
          y : this.chartDict[key].histValue,
          type : 'bar',
          name : this.chartDict[key].label,
          marker : {color : this.chartDict[key].color}
        })
        this.lastAmount = this.chartDict[key].histValue[this.chartDict[key].histValue.length - 1]
      } else {
        result.push({
          x : this.timeline,
          y : this.chartDict[key].histValue,
          mode: 'lines+markers',
          type : 'scatter',
          name : this.chartDict[key].label,
          marker : {color : this.chartDict[key].color}
        })
        this.lastAmount = this.chartDict[key].histValue[this.chartDict[key].histValue.length - 1]
      }
    })
    return result
  }

  generateMonthlyAndYearlyGrowth(index){
    var specialDate = new Date(2024, 8, 1, 2);
    let growthOnIndex = this.generateGrowth(index)
    let dataMonthlyGrowth = []
    this.yearRange = []
    let start = new Date(this.timeline[1]).getFullYear()
    let end = new Date(this.timeline[this.timeline.length - 1]).getFullYear()
    for (let i = end ; i >= start ; i --){
      dataMonthlyGrowth.push({
        x : [],
        y : [],
        name : i,
        type : "bar"
      })
      this.yearRange.push(i)
    }
    let setDate = new Date(2024, 9, 1, 2)
    for (let j = 0 ; j < growthOnIndex[0].y.length ; j ++){
      let elDate = new Date(growthOnIndex[0].x[j])
      let elDate2 = new Date(growthOnIndex[0].x[j])
      if(elDate.getTime() === setDate.getTime()){
        elDate2.setDate(elDate.getDate() + -15)
        let elDateYear = elDate2.getFullYear()
        let elDateMonth = elDate2.toLocaleString('default', { month: 'long' });
        let elDateMonthCap = elDateMonth.charAt(0).toUpperCase() + elDateMonth.slice(1)
        let indexData = end - elDateYear
        dataMonthlyGrowth[indexData].x.push(elDateMonthCap)
        dataMonthlyGrowth[indexData].y.push(0)
      }
      elDate.setDate(elDate.getDate() - 15)
      if(elDate > specialDate){
        elDate.setDate(elDate.getDate() + 31)
      }
      let elDateYear = elDate.getFullYear()
      let elDateMonth = elDate.toLocaleString('default', { month: 'long' });
      let elDateMonthCap = elDateMonth.charAt(0).toUpperCase() + elDateMonth.slice(1)
      let indexData = end - elDateYear
      dataMonthlyGrowth[indexData].x.push(elDateMonthCap)
      dataMonthlyGrowth[indexData].y.push(growthOnIndex[0].y[j])
    }
    this.updateYearlyGrowth(dataMonthlyGrowth)
    return dataMonthlyGrowth
  }

  updateYearlyGrowth(monthlyGrowth){
    this.yearGrowth = []
    this.yearRange.forEach(el => {
      this.yearGrowth.push({
        year : el,
        sum : 0,
        nb : 0,
        avg : 0
      })
    })
    monthlyGrowth.forEach(el => {
      let yearValue = el.name
      let index = this.yearGrowth.findIndex(years => years.year == yearValue)
      el.y.forEach(growth => {
        if(growth != 0){
          this.yearGrowth[index].sum += growth
          this.yearGrowth[index].nb ++
        }
      })
      if(this.yearGrowth[index].nb !== 0){
        this.yearGrowth[index].avg = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0}).format(this.yearGrowth[index].sum / this.yearGrowth[index].nb)
      }
    })
  }

  generateGrowth(index){
    let result = []
    let key = Object.keys(this.chartDict)[index]
    let growthValues = []
    for (let i = 1 ; i < this.chartDict[key].histValue.length ; i++){
      let growthValue = this.chartDict[key].histValue[i] - this.chartDict[key].histValue[i-1]
      if(Math.abs(growthValue) > 1000000){
        growthValue = 0
      }
      growthValues.push(growthValue)
    }
    result.push({
      x : this.timeline.slice(1, this.timeline.length),
      y : growthValues,
      mode: 'lines+markers',
      type : 'scatter',
      name : `Growth of ${this.chartDict[key].label}`,
      marker : {color : this.chartDict[key].color}
    })
    return result
  }

  fillChartDict(dataD, dataT, dataC){
    let paddedArrayD = [...Array(this.deltaTimelineD).fill(0)]
    let paddedArrayC = [...Array(this.deltaTimelineC).fill(0)]
    this.chartDict['savings_property'].histValue = paddedArrayD.concat(dataD.map(({savings_property})=> savings_property == null ? 0 : Math.floor(savings_property)))
    this.chartDict['pension_savings'].histValue = paddedArrayD.concat(dataD.map( ({pension_savings})=> pension_savings == null ? 0 : Math.floor(pension_savings)))
    this.chartDict['savings_account'].histValue = paddedArrayD.concat(dataD.map( ({savings_account})=> savings_account == null ? 0 : Math.floor(savings_account)))
    this.chartDict['sap_stock_d'].histValue = paddedArrayD.concat(dataD.map( ({sap_stock})=> sap_stock == null ? 0 :Math.floor(sap_stock)))
    this.chartDict['action_revolut_d'].histValue = paddedArrayD.concat(dataD.map( ({action_revolut})=> action_revolut == null ? 0 :Math.floor(action_revolut)))
    this.chartDict['current_account'].histValue = paddedArrayD.concat(dataD.map( ({current_account})=> current_account == null ? 0 : Math.floor(current_account)))

    this.chartDict['shared_account'].histValue = paddedArrayC.concat(dataC.map( ({shared_account})=> shared_account == null ? 0 : Math.floor(shared_account)))
    this.chartDict['debt_kb'].histValue = paddedArrayC.concat(dataC.map( ({debt_kb})=> debt_kb == null ? 0 : -Math.floor(debt_kb)))
    this.chartDict['debt_dad'].histValue = paddedArrayC.concat(dataC.map( ({debt_dad_cz})=> debt_dad_cz == null ? 0 : -Math.floor(debt_dad_cz)))

    this.chartDict['appart_otroko'].histValue = dataT.map( ({appart_otroko})=> appart_otroko == null ? 0 : Math.floor(appart_otroko) * this.CZKValueAvantAppart)
    this.chartDict['csob_savings'].histValue = dataT.map( (csob_savings, index)=> {
      if(csob_savings.csob_savings == null) {return 0}
      if (index <= 75) {return Math.floor(csob_savings.csob_savings)} else {return Math.floor(csob_savings.csob_savings)}
    })
    this.chartDict['compte_courant_cz'].histValue = dataT.map( (compte_courant_cz, index)=> {
      if(compte_courant_cz.compte_courant_cz == null) {return 0}
      if (index <= 75) {return Math.floor(compte_courant_cz.compte_courant_cz) * this.CZKValueAvantAppart} else {return Math.floor(compte_courant_cz.compte_courant_cz)}
    })
    this.chartDict['ceska_sporitelny'].histValue = dataT.map( (ceska_sporitelny, index)=> {
      if(ceska_sporitelny.ceska_sporitelny == null) {return 0}
      if (index <= 75) {return Math.floor(ceska_sporitelny.ceska_sporitelny) * this.CZKValueAvantAppart} else {return Math.floor(ceska_sporitelny.ceska_sporitelny)}
    })
    this.chartDict['kooperativa'].histValue = dataT.map( (kooperativa, index)=> {
      if(kooperativa.kooperativa == null) {return 0}
      if (index <= 75) {return Math.floor(kooperativa.kooperativa) * this.CZKValueAvantAppart} else {return Math.floor(kooperativa.kooperativa)}
    })
    this.chartDict['compte_courant'].histValue = dataT.map( (compte_courant, index)=> {
      if(compte_courant.compte_courant == null) {return 0}
      if (index <= 75) {return Math.floor(compte_courant.compte_courant) * this.CZKValueAvantAppart} else {return Math.floor(compte_courant.compte_courant) * dataT[index]['cz_to_eur']}
    })
    this.chartDict['ldd'].histValue = dataT.map( (ldd, index)=> {
      if(ldd.ldd == null) {return 0}
      if (index <= 75) {return Math.floor(ldd.ldd) * this.CZKValueAvantAppart} else {return Math.floor(ldd.ldd) * dataT[index]['cz_to_eur']}
    })
    this.chartDict['pel'].histValue = dataT.map( ({pel})=> Math.floor(pel) * this.CZKValueAvantAppart)
    this.chartDict['av_ca'].histValue = dataT.map( ({av_ca})=> Math.floor(av_ca) * this.CZKValueAvantAppart)
    this.chartDict['generali_actif_general'].histValue = dataT.map( ({generali_actif_general})=> Math.floor(generali_actif_general) * this.CZKValueAvantAppart)
    this.chartDict['generali_sci'].histValue = dataT.map( ({generali_sci})=> Math.floor(generali_sci) * this.CZKValueAvantAppart)
    this.chartDict['generali_opci'].histValue = dataT.map( ({generali_opci})=> Math.floor(generali_opci) * this.CZKValueAvantAppart)
    this.chartDict['october'].histValue = dataT.map( (october, index)=> {
      if(october.october == null) {return 0}
      if (index <= 75) {return Math.floor(october.october) * this.CZKValueAvantAppart} else {return Math.floor(october.october) * dataT[index]['cz_to_eur']}
    })
    this.chartDict['action_ws'].histValue = dataT.map( ({action_ws})=> Math.floor(action_ws) * this.CZKValueAvantAppart)
    this.chartDict['action_sap_t'].histValue = dataT.map( (action_sap, index)=> {
      if(action_sap.action_sap == null) {return 0}
      if (index <= 75) {return Math.floor(action_sap.action_sap) * this.CZKValueAvantAppart} else {return Math.floor(action_sap.action_sap) * dataT[index]['cz_to_eur']}
    })
    this.chartDict['action_revolut_t'].histValue = dataT.map( (action_revolut, index)=> {
      if(action_revolut.action_revolut == null) {return 0}
      if (index <= 75) {return Math.floor(action_revolut.action_revolut) * this.CZKValueAvantAppart} else {return Math.floor(action_revolut.action_revolut) * dataT[index]['cz_to_eur']}
    })
    this.chartDict['eToro'].histValue = dataT.map( (eToro, index)=> {
      if(eToro.eToro == null) {return 0}
      if (index <= 75) {return Math.floor(eToro.eToro) * this.CZKValueAvantAppart} else {return Math.floor(eToro.eToro) * dataT[index]['cz_to_eur']}
    })
    this.chartDict['advize'].histValue = dataT.map( ({advize})=> Math.floor(advize) * this.CZKValueAvantAppart)
    this.chartDict['crypto'].histValue = dataT.map( (crypto, index)=> {
      if(crypto.crypto == null) {return 0}
      if (index <= 75) {return Math.floor(crypto.crypto) * this.CZKValueAvantAppart} else {return Math.floor(crypto.crypto) * dataT[index]['cz_to_eur']}
    })
    this.chartDict['term_deposit'].histValue = dataT.map( ({term_deposit})=> term_deposit == null ? 0 : Math.floor(term_deposit) * this.CZKValueAvantAppart)
    this.chartDict['csob_bohatstvi'].histValue = dataT.map( (csob_bohatstvi, index)=> {
      if(csob_bohatstvi.csob_bohatstvi == null) {return 0}
      if (index <= 75) {return Math.floor(csob_bohatstvi.csob_bohatstvi) * this.CZKValueAvantAppart} else {return Math.floor(csob_bohatstvi.csob_bohatstvi)}
    })
    this.chartDict['csob_velmi_opatrny'].histValue = dataT.map( (csob_velmi_opatrny, index)=> {
      if(csob_velmi_opatrny.csob_velmi_opatrny == null) {return 0}
      if (index <= 75) {return Math.floor(csob_velmi_opatrny.csob_velmi_opatrny) * this.CZKValueAvantAppart} else {return Math.floor(csob_velmi_opatrny.csob_velmi_opatrny)}
    })
    this.chartDict['csob_akciovy'].histValue = dataT.map( (csob_akciovy, index)=> {
      if(csob_akciovy.csob_akciovy == null) {return 0}
      if (index <= 75) {return Math.floor(csob_akciovy.csob_akciovy) * this.CZKValueAvantAppart} else {return Math.floor(csob_akciovy.csob_akciovy)}
    })  
    this.chartDict['tickets_resto'].histValue = dataT.map( (tickets_resto, index)=> {
      if(tickets_resto.tickets_resto == null) {return 0}
      if (index <= 75) {return Math.floor(tickets_resto.tickets_resto) * this.CZKValueAvantAppart} else {return Math.floor(tickets_resto.tickets_resto)}
    })
    
    this.chartDict['current_account'].todayValue = this.last(this.chartDict['current_account'].histValue)
    this.chartDict['action_revolut_d'].todayValue = this.last(this.chartDict['action_revolut_d'].histValue)
    this.chartDict['sap_stock_d'].todayValue = this.last(this.chartDict['sap_stock_d'].histValue)
    this.chartDict['savings_account'].todayValue = this.last(this.chartDict['savings_account'].histValue)
    this.chartDict['savings_property'].todayValue = this.last(this.chartDict['savings_property'].histValue)
    this.chartDict['pension_savings'].todayValue = this.last(this.chartDict['pension_savings'].histValue)

    this.chartDict['shared_account'].todayValue = this.last(this.chartDict['shared_account'].histValue)
    this.chartDict['debt_kb'].todayValue = this.last(this.chartDict['debt_kb'].histValue)
    this.chartDict['debt_dad'].todayValue = this.last(this.chartDict['debt_dad'].histValue)
    
    this.chartDict['appart_otroko'].todayValue = this.last(this.chartDict['appart_otroko'].histValue)
    this.chartDict['compte_courant'].todayValue = this.last(this.chartDict['compte_courant'].histValue)
    this.chartDict['compte_courant_cz'].todayValue = this.last(this.chartDict['compte_courant_cz'].histValue)
    this.chartDict['ldd'].todayValue = this.last(this.chartDict['ldd'].histValue)
    this.chartDict['pel'].todayValue = this.last(this.chartDict['pel'].histValue)
    this.chartDict['av_ca'].todayValue = this.last(this.chartDict['av_ca'].histValue)
    this.chartDict['generali_actif_general'].todayValue = this.last(this.chartDict['generali_actif_general'].histValue)
    this.chartDict['generali_sci'].todayValue = this.last(this.chartDict['generali_sci'].histValue)
    this.chartDict['generali_opci'].todayValue = this.last(this.chartDict['generali_opci'].histValue)
    this.chartDict['october'].todayValue = this.last(this.chartDict['october'].histValue)
    this.chartDict['action_ws'].todayValue = this.last(this.chartDict['action_ws'].histValue)
    this.chartDict['action_sap_t'].todayValue = this.last(this.chartDict['action_sap_t'].histValue)
    this.chartDict['action_revolut_t'].todayValue = this.last(this.chartDict['action_revolut_t'].histValue)
    this.chartDict['eToro'].todayValue = this.last(this.chartDict['eToro'].histValue)
    this.chartDict['term_deposit'].todayValue = this.last(this.chartDict['term_deposit'].histValue)
    this.chartDict['csob_bohatstvi'].todayValue = this.last(this.chartDict['csob_bohatstvi'].histValue)
    this.chartDict['csob_velmi_opatrny'].todayValue = this.last(this.chartDict['csob_velmi_opatrny'].histValue)
    this.chartDict['csob_savings'].todayValue = this.last(this.chartDict['csob_savings'].histValue)
    this.chartDict['csob_akciovy'].todayValue = this.last(this.chartDict['csob_akciovy'].histValue)
    this.chartDict['advize'].todayValue = this.last(this.chartDict['advize'].histValue)
    this.chartDict['crypto'].todayValue = this.last(this.chartDict['crypto'].histValue)
    this.chartDict['tickets_resto'].todayValue = this.last(this.chartDict['tickets_resto'].histValue)
    this.chartDict['kooperativa'].todayValue = this.last(this.chartDict['kooperativa'].histValue)
    
    this.chartDict['total_savings'].histValue = this.getTotalSavings()

    this.calculateGrowths()
    }

    calculateGrowths() {
      for(let i = 0; i < this.allLabels.length ; i ++){
        let lastKeyGrowth = this.generateGrowth(i)[0]['y'].slice(-1)[0]
        if (lastKeyGrowth > 0) {
        this.upGrowthArray.push({
          label : this.chartDict[Object.keys(this.chartDict)[i]].label,
          growth : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0}).format(lastKeyGrowth)
        })
      } else if (lastKeyGrowth < 0){
        this.downGrowthArray.push({
          label : this.chartDict[Object.keys(this.chartDict)[i]].label,
          growth : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0}).format(lastKeyGrowth)
        })
      }
    }
    this.upGrowthArray.sort((a,b)=>b.growth - a.growth)
    this.downGrowthArray.sort((a,b)=>a.growth - b.growth)
  }
  
  getTotalSavings(): any[] {
    let result = [];
    for(let i = 0; i< this.timeline.length; i++){
      result.push(this.chartDict['pension_savings'].histValue[i] + this.chartDict['savings_property'].histValue[i] + this.chartDict['savings_account'].histValue[i] + this.chartDict['sap_stock_d'].histValue[i] + this.chartDict['action_revolut_d'].histValue[i] + this.chartDict['current_account'].histValue[i] + this.chartDict['appart_otroko'].histValue[i] + this.chartDict['compte_courant'].histValue[i] + this.chartDict['compte_courant_cz'].histValue[i] + this.chartDict['ldd'].histValue[i] + this.chartDict['pel'].histValue[i] + this.chartDict['av_ca'].histValue[i] + this.chartDict['generali_actif_general'].histValue[i] + this.chartDict['generali_opci'].histValue[i] + this.chartDict['generali_sci'].histValue[i] + this.chartDict['october'].histValue[i] + this.chartDict['action_ws'].histValue[i] + this.chartDict['action_sap_t'].histValue[i] + this.chartDict['advize'].histValue[i] + this.chartDict['crypto'].histValue[i] + this.chartDict['tickets_resto'].histValue[i] + this.chartDict['term_deposit'].histValue[i] + this.chartDict['csob_bohatstvi'].histValue[i] + this.chartDict['csob_velmi_opatrny'].histValue[i] + this.chartDict['action_revolut_t'].histValue[i] + this.chartDict['ceska_sporitelny'].histValue[i] + this.chartDict['kooperativa'].histValue[i] + this.chartDict['shared_account'].histValue[i] + this.chartDict['csob_savings'].histValue[i] + this.chartDict['eToro'].histValue[i])
    }
    return result
  }

  getTotalDebts(){
    this.totalDebt = this.chartDict['debt_kb'].histValue[this.timeline.length-1] + this.chartDict['debt_dad'].histValue[this.timeline.length-1]
  }

  last(array) {
    return array[array.length - 1];
  }
  
  getClassCam() {
    if(this.displayCamembert){
      return {'showCam': true}
    } else {
      return {'hideCam': true}
    }
  }

  getClassRest() {
    if(this.displayingRisk || this.isFocused){
      return {'hideRest': true}
    } else {
      return {'showRest': true}
    }
  }

  sumArray(a, b) {
    var c = [];
    for (var i = 0; i < Math.max(a.length, b.length); i++) {
      c.push((a[i] || 0) + (b[i] || 0));
    }
    return c;
  }

  
  showPanel(){
    let menu = document.getElementById("focusMenu");
    menu.classList.add("toggleMenu")
    this.isDroppedDown = 1
  }

  checkFocus(){
    if(this.isDroppedDown == 1){
      this.hidePanel()
    } else {
      this.showPanel()
    }
  }

  hidePanel(){
    let menu = document.getElementById("focusMenu");
    menu.classList.remove("toggleMenu")
    this.isDroppedDown = 0
  }

  lerp = function(a, b, u) {
    return (1 - u) * a + u * b;
  };

  fade = function(start, end, nbSteps) {
    let step_u = 1.0 / nbSteps;
    let u = 0.0;
    let result = []
    for (let i = 0 ; i <= nbSteps ; i ++){
      let r = Math.round(this.lerp(start.r, end.r, u));
      let g = Math.round(this.lerp(start.g, end.g, u));
      let b = Math.round(this.lerp(start.b, end.b, u));
      let colorname = 'rgb(' + r + ',' + g + ',' + b + ')';
      u += step_u;
      result.push(colorname)
    }
    return result
};

  fillColors(){
    // past deni's colors
    // let colorArrayGoodLiquidity = this.fade({r:121, g: 250, b: 197}, {r:0, g: 138, b: 93}, 2)
    // let colorArrayMediumLiquidity = this.fade({r:0, g: 183, b: 255}, {r:59, g: 72, b: 86}, 3)
    // let colorArrayLowLiquidity = this.fade({r:255, g: 133, b: 167}, {r:154, g: 0, b: 0}, 2)
    let colorArrayDeni = this.fade({r:249, g: 248, b: 113}, {r:245, g: 245, b: 15}, 6)
    let colorArrayThib = this.fade({r:79, g:101, b:125}, {r:51, g: 66, b: 82}, 14)
    let colorArrayCommon = this.fade({r:72, g:203, b:153}, {r:49, g:175, b:137}, 1)
    let colorArrayDebt = this.fade({r:249, g:131, b:113}, {r:248, g:118, b:98}, 2)
    this.chartDict =   {
      shared_account : {
        histValue : [],
        todayValue : [],
        label : "Shared account",
        color : colorArrayCommon[0]
      },
      debt_kb : {
        histValue : [],
        todayValue : [],
        label : "Mortgage KB",
        color : colorArrayDebt[1]
      },
      debt_dad : {
        histValue : [],
        todayValue : [],
        label : "Loan Dad",
        color : colorArrayDebt[0]
      },
      current_account : {
        histValue : [],
        todayValue : [],
        label : "Current Account D",
        color : colorArrayDeni[0]
      },
      pension_savings : {
        histValue : [],
        todayValue : [],
        label : "Pension savings D",
        color : colorArrayDeni[4]
      },
      action_revolut_d : {
        histValue : [],
        todayValue : [],
        label : "Stocks Revolut D",
        color : colorArrayDeni[2]
      },
      savings_property : {
        histValue : [],
        todayValue : [],
        label : "Savings Real Estate D",
        color : colorArrayDeni[3]
      },
      savings_account : {
        histValue : [],
        todayValue : [],
        label : "Savings D",
        color : colorArrayDeni[4]
      },
      sap_stock_d : {
        histValue : [],
        todayValue : [],
        label : "SAP stocks D",
        color : colorArrayDeni[5]
      },
      compte_courant : {
        histValue : [],
        todayValue : [],
        label : "Current Account FR T",
        color : colorArrayThib[0]
      },
      compte_courant_cz : {
        histValue : [],
        todayValue : [],
        label : "Current Account CZ T",
        color : colorArrayThib[1]
      },
      ldd : {
        histValue : [],
        todayValue : [],
        label : "Livret A",
        color : colorArrayThib[2]
      },
      // ldd_lucie : {
      //   histValue : [],
      //   todayValue : [],
      //   label : "Livret A Lucie",
      //   color : colorArrayGoodLiquidity[3]
      // },
      // pel_lucie : {
      //   histValue : [],
      //   todayValue : [],
      //   label : "PEL Lucie",
      //   color : colorArrayMediumLiquidity[1]
      // },
      // av_lucie : {
      //   histValue : [],
      //   todayValue : [],
      //   label : "Assurance vie Lucie",
      //   color : colorArrayMediumLiquidity[6]
      // },
      october : {
        histValue : [],
        todayValue : [],
        label : "October",
        color : colorArrayThib[3]
      },
      action_sap_t : {
        histValue : [],
        todayValue : [],
        label : "SAP stocks T",
        color : colorArrayThib[4]
      },
      action_revolut_t : {
        histValue : [],
        todayValue : [],
        label : "Revolut Stocks T",
        color : colorArrayThib[5]
      },
      eToro : {
        histValue : [],
        todayValue : [],
        label : "eToro",
        color : colorArrayThib[6]
      },
      crypto : {
        histValue : [],
        todayValue : [],
        label : "Cryptocurrency",
        color : colorArrayThib[7]
      },
      csob_savings : {
        histValue : [],
        todayValue : [],
        label : "CSOB Savings T",
        color : colorArrayThib[8]
      },
      csob_bohatstvi : {
        histValue : [],
        todayValue : [],
        label : "CSOB Bohatstvi",
        color : colorArrayThib[9]
      },
      csob_velmi_opatrny : {
        histValue : [],
        todayValue : [],
        label : "CSOB Velmi Opatrny",
        color : colorArrayThib[10]
      },
      csob_akciovy : {
        histValue : [],
        todayValue : [],
        label : "CSOB Akciovy",
        color : colorArrayThib[11]
      },
      ceska_sporitelny : {
        histValue : [],
        todayValue : [],
        label : "Ceska Sporitelny",
        color : colorArrayThib[12]
      },
      kooperativa : {
        histValue : [],
        todayValue : [],
        label : "Kooperativa",
        color : colorArrayThib[13]
      },
      tickets_resto : {
        histValue : [],
        todayValue : [],
        label : "Tickets restaurant",
        color : colorArrayThib[14]
      },   
      appart_otroko : {
        histValue : [],
        todayValue : [],
        label : "Appart Otrokovice",
        color : '#F9F871'
      },
      av_ca : {
        histValue : [],
        todayValue : [],
        label : "Assurance vie CA",
        color : colorArrayThib[0]
      },
      generali_actif_general : {
        histValue : [],
        todayValue : [],
        label : "Generali Actif General",
        color : colorArrayThib[0]
      },
      generali_sci : {
        histValue : [],
        todayValue : [],
        label : "Generali SCI",
        color : colorArrayThib[0]
      },
      generali_opci : {
        histValue : [],
        todayValue : [],
        label : "Generali OPCI",
        color : colorArrayThib[0]
      },
      advize : {
        histValue : [],
        todayValue : [],
        label : "Advize",
        color : colorArrayThib[0]
      },
      term_deposit : {
        histValue : [],
        todayValue : [],
        label : "Term deposit",
        color : colorArrayThib[0]
      },
      pel : {
        histValue : [],
        todayValue : [],
        label : "PEL",
        color : colorArrayThib[0]
      },
      action_ws : {
        histValue : [],
        todayValue : [],
        label : "Action Wavestone",
        color : colorArrayThib[0]
      },
      total_savings : {
        histValue : [],
        todayValue : [],
        label : "Total",
        color : '#f75840'
      },
    }
  
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if(targetElement.id !== "dropdownButton"){
      this.hidePanel()
    }
  }
}
